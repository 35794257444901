import { importExpr } from '@angular/compiler/src/output/output_ast';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import WebViewer from '@pdftron/webviewer';

import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';


@Component({
  selector: 'app-info',
  templateUrl: './info.page.html',
  styleUrls: ['./info.page.scss'],
})
export class InfoPage implements OnInit{
  // @ViewChild('viewer') viewerRef: ElementRef;

  public page = 2;

  public pageLabel!: string;


  pdfSrc;
  title;

  constructor(private modalController: ModalController) {
  }


  // ngAfterViewInit(): void {
  //   WebViewer({
  //     path: '../assets/lib',
  //     initialDoc: '/assets/files/NSSM-BROCHURE.pdf'
  //   }, this.viewerRef.nativeElement).then(istance => {

  //   });
  // }

  ngOnInit(): void {
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
