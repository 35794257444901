import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-priorita-risparmio',
  templateUrl: './priorita-risparmio.page.html',
  styleUrls: ['./priorita-risparmio.page.scss'],
})
export class PrioritaRisparmioPage implements OnInit {
  constructor(private modalController: ModalController,) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
